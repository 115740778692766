<template>
  <div class="bgimg flex-column-between">
    <div class="attendHer">
      <div class="back flex-align">
        <img src="@/assets/attend/back.png" @click=" attenddialog = true;" style="cursor: pointer" />
        <span @click=" attenddialog = true">下课</span>
      </div>
      <div class="content flex-layout">
        <div class="type">
          <div class="type_item flex-align" :class="{ active: contentType == 'play' }" @click="toggleType('play')">
            <div class="iconbox">
              <img src="@/assets/attend/play.png" class="iconbox1" />
            </div>
            <span>播放</span>
          </div>
          <!-- v-if="false" -->
          <div class="type_item flex-align" v-if="false" :class="{ active: contentType == 'ppt' }"
            @click="toggleType('ppt')">
            <div class="iconbox">
              <img src="@/assets/attend/ppt.png" class="iconbox2" />
            </div>
            <span>PPT</span>
          </div>
          <div class="type_item flex-align" :class="{ active: contentType == 'font' }" @click="toggleType('font')">
            <div class="iconbox">
              <img src="@/assets/attend/font.png" class="iconbox3" />
            </div>
            <span>写字面板</span>
          </div>
        </div>
        <!-- flex-align-center -->
        <!-- flex-column-center -->
        <div class="middle_wrap flex-column-center">
          <div class="title flex-align-center">
            <div class="point"></div>
            <div class="text">{{ course_name }}</div>
            <div class="point"></div>
          </div>

          <div class="middle">
            <!-- 视频播放 -->
            <div class="play" id="play_id" v-show="contentType == 'play'">
              <!-- <d-player ref="player" :options="options" @ended="endbtn" @play="attplay" @pause="attpause"
                v-show="false"></d-player> -->
              <div id="player"></div>
            </div>
            <!-- PPT演示 -->
            <!-- contentType == 'ppt' -->
            <div class="ppt flex-layout" v-show="false">
              <div class="ppttriangle flex-align-around">
                <img src="@/assets/task/l_triangle.png" @click="slidePre_ppt()" v-if="l_triangle_show" />
              </div>
              <div class="pptslider">
                <slider ref="pptref" :options="pptinit" @slide="slide_ppt" @tap="onTap_ppt" @init="onInit_ppt">
                  <slideritem v-for="(item, index) in textlist.length * 2" :key="index">
                    <!-- :style="item.style2" -->
                    <!-- {{item.name}} -->
                    <!-- <img :src="item.animateUrl" /> -->
                    <div style="color: red">{{ index }}</div>
                  </slideritem>
                </slider>
              </div>
              <div class="ppttriangle flex-align-around">
                <img src="@/assets/task/r_triangle.png" @click="slideNext_ppt()" v-if="r_triangle_show" />
              </div>
            </div>
            <!-- 写字面板 -->
            <div class="font flex-align" v-show="contentType == 'font'">
              <div class="imgDraw">
                <div class="d_body flex-align-between">
                  <div class="boy boy1">
                    <div class="board" v-show="can_show1">
                      <img :src="gif_url" id="git" @click="gif_btn" />
                    </div>
                    <div class="board" ref="board" v-show="can_show2">
                      <canvas id="ctx_front" ref="ctx_front" :style="'cursor:' + cursor"></canvas>
                      <canvas id="ctx_base" ref="ctx_base" :style="'cursor:' + cursor"></canvas>
                      <canvas id="ctx_back" ref="ctx_back" :style="'cursor:' + cursor"></canvas>
                    </div>
                  </div>
                  <div class=" boy boy2">
                    <div class="settings_box">
                      <ul>
                        <li>
                          <div @click="handleChangeToolType(1)">标记</div>
                          <div class="flex-align">
                            <span class="color_list" v-for="(item, index) in defaultColor1" :key="index"
                              :style="{ background: item }" @click="color_btn(item)"></span>
                          </div>
                        </li>
                        <li>
                          <div @click="handleChangeToolType(5)">橡皮擦</div>
                          <div class="flex-align">
                            <span class="color_list2" v-for="item in eraser_num" :key="item"
                              @click="eraser_btn(item)"></span>
                          </div>
                        </li>
                        <li>
                          <div @click="handleClearCanvas()">清除</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 文字轮播 -->
        <div class="fontList flex-column">
          <div class="triangle flex-align-center">
            <img @click="slidePre" src="@/assets/attend/t_triangle.png" v-if="t_triangle_show" />
          </div>
          <slider ref="slider" :options="sliderinit" @slide="slide" @tap="onTap" @init="onInit">
            <slideritem v-for="(item, index) in textlist" :key="index" :style="item.style">
              <div class="textbox" :class="{ active: index == activeindex }" @click="changebtn(index,item.id)">
                {{ item.name }}
                <!-- id -->
              </div>
            </slideritem>
          </slider>
          <div class="triangle flex-align-center">
            <img @click="slideNext" src="@/assets/attend/b_triangle.png" v-if="b_triangle_show" />
          </div>
        </div>
      </div>
    </div>
    <div class="attend_btm">
      <div class="taibg">
      </div>
      <div class="bottomdialog">
        <el-dialog title="提示" :visible.sync="attenddialog" width="30%" center>
          <span>当前正在上课，是否确认退出？</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="attenddialog = false">取 消</el-button>
            <el-button type="primary" @click="attenddialog = false,goback()">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import axios from "axios";
  import {
    slider,
    slideritem
  } from "vue-concise-slider";
  import cursors from "./js/cursor";
  import {
    mapState,
    mapMutations
  } from "vuex";
  export default {
    data() {
      return {
        attenddialog: false,
        imgas: require('../../assets/attend/16.gif'),
        backgroundImage: "url(" + require("../../assets/homepage/bg.png") + ")",
        contentType: "play",
        options1: {
          currentPage: 0,
        },
        textlist: [],
        sliderinit: {
          direction: "vertical", //方向设置，垂直滚动
          slidesToScroll: 6, //每次滑动项数
          freeze: true, //禁止拖动
        },
        pptlist: [],
        pptinit: {
          direction: "horizontal", //方向设置，垂直滚动

          slidesToScroll: 1, //每次滑动项数
          freeze: true, //禁止拖动
        },
        t_triangle_show: false,
        b_triangle_show: true,
        l_triangle_show: false,
        r_triangle_show: true,
        activeindex: 0,
        vodPlayerJs: 'https://player.polyv.net/script/player.js',
        playerdom: '',
        vid: '',
        playsin: '',
        firstPlayFlag: true,

        player: '',
        // 画板
        show: true,
        defaultColor: "#F32D2D",
        defaultColor1: ["#F32D2D", "#2BD147", "#2CA3FE"],
        eraser_num: [30, 20, 10],
        cursor: `url('${cursors.pen}'),auto`,
        strokes: 5,
        eraser: 5,
        settings: [{
            icon: "#icon-youqitong_huaban1",
            name: "颜色",
            fun: "",
          },
          {
            icon: "#icon-huabi_huaban1",
            name: "粗细",
            fun: "",
          },
        ],
        activeTool: 1,
        tools: [{
            icon: "#icon-huabi_huaban1",
            name: "画笔",
            toolType: 1,
          },
          {
            icon: "#icon-xiangpi_huaban1",
            name: "橡皮",
            toolType: 5,
          },
        ],
        btns: [{
          icon: "#icon-lajixiang_huaban1",
          name: "清除",
          fun: () => {
            return this.handleClearCanvas();
          },
        }, ],
        canvas_front: null,
        canvas_back: null,
        canvas_base: null,
        ctx_base: null,
        ctx_front: null,
        ctx_back: null,
        currentImg: {
          url: "",
          width: "",
          height: "",
          scale: 1,
          index: 0,
        },
        canDraw: false,
        text: "",
        canvasStore: [],
        prevDis: true,
        nextDis: true,
        baseMap: "",
        tl: 0,
        tt: 0,
        // 画板
        course_name: "",
        can_show1: true,
        can_show2: false,
        gif_url: require("../../assets/attend/matts.png"),
        gif_num: 0,
        timer: '',
        startTimer: '',
        attplayShow: true,
      };
    },
    components: {
      slider,
      slideritem,
    },
    computed: {
      ...mapState({
        attend_id: (state) => state.home.attend_id,
      }),
    },
    mounted() {


      this.$nextTick(() => {
        let userJsonStr = sessionStorage.getItem("attend_id");
        let attend_id = JSON.parse(userJsonStr);
        this.course_name = attend_id.course_name;
        let unitId = attend_id.unitId;
        let textId = attend_id.textId;
        this.Course_content(unitId, textId);
        this.gif_show();
        this.putdate();
        this.timer = window.setInterval(() => {
          setTimeout(() => {
            this.putdate();
          }, 0)
        }, 60000)

      });
      this.onPlayerInitOver = window.s2j_onPlayerInitOver;
    },
    methods: {
      // 保利威视频插件
      loadPlayerScript(callback) {

        if (!window.polyvPlayer) {
          const myScript = document.createElement('script');
          myScript.setAttribute('src', this.vodPlayerJs);
          myScript.onload = callback;
          document.body.appendChild(myScript);
        } else {
          callback();
        }

      },

      loadPlayer(vid, playsin) {

        const polyvPlayer = window.polyvPlayer;
        this.playerdom = new polyvPlayer({
          wrap: '#player',
          vid: this.vid,
          'loop': false,
          'autoplay': false,
          'forceH5': true,
          'playsafe': this.playsin,
          'hideSwitchPlayer':true
        });
        this.playerdom.on('s2j_onPlayOver', this.ended);
        this.playerdom.on('s2j_onVideoPlay', this.attplay);
        this.playerdom.on('s2j_onVideoPause', this.attpause);
        this.playerdom.on('s2j_onFullScreen', this.fullScreen);
        this.playerdom.on('s2j_onNormalScreen', this.reduce);
        let dom = document.getElementsByClassName('pv-video')[0];
        dom.classList.add("screen");
        // console.log(dom)
        // console.log('111', this.playerdom)
      },
      async getv(vid) {
        let data = {
          vid: vid
        };
        let resData = await this.$Api.Home.getVid(data);
        // console.log('resData111', resData.data.playsafe);
        this.vid = vid;
        this.playsin = resData.data.playsafe;
        const option = {
          vid: this.vid,
          playsafe: this.playsin,
        }
        if (this.firstPlayFlag) {
          this.loadPlayerScript(this.loadPlayer);
          this.firstPlayFlag = false;
        } else {
          this.playerdom.changeVid(option);
        }
      },
      attplay() {
        let id = this.textlist[this.activeindex].id;
        this.statistics(id);
        // console.log('播放')
      },
      attpause() {
        clearInterval(this.startTimer);
        // console.log('暂停')
      },
      fullScreen() {
        let dom = document.getElementsByClassName('pv-video')[0];
        dom.classList.remove("screen");
        // console.log(dom)
      },
      reduce() {
        let dom = document.getElementsByClassName('pv-video')[0];
        dom.classList.add("screen");
        // console.log(dom)
      },
      //结束播放
      ended() {
        if (this.activeindex < this.textlist.length - 1) {
          this.activeindex++;
          this.getv(this.textlist[this.activeindex].hardVideo1);

          let aurl = this.textlist[this.activeindex].animateUrl;
          this.currentImg.url = this.textlist[this.activeindex].animateUrl;
          this.canvasStore = this.textlist[this.activeindex].animateUrl;
          this.baseMap = this.textlist[this.activeindex].animateUrl;
          this.handleInitCanvas(aurl);
          let id = this.textlist[this.activeindex].id;
          this.statistics(id);
          // this.player.play();
          if (this.activeindex % 5 == 0 && this.activeindex > 0) {
            this.$refs.slider.$emit("slideNext");
          }
        } else {
          // alert("最后一节");
        }

      },

      ...mapMutations(["hint_data"]),
      //课程内容信息接口请求
      async Course_content(unitId, textId) {
        let data = {
          unitId: unitId,
          textId: textId,
        };
        let resData = await this.$Api.Home.getTextInfo(data);
        this.textlist = resData.data;
        if (this.textlist.length != null) {
          // console.log('this.activeindex', this.activeindex);
          this.getv(this.textlist[this.activeindex].hardVideo1);
          let aurl = this.imgas;
          this.currentImg.url = this.imgas;
          this.canvasStore = this.imgas;
          this.baseMap = this.imgas;
          this.handleInitCanvas(aurl);
        }
      },
      //课程内容信息接口请求

      // 更新时间
      async putdate() {
        let id = sessionStorage.getItem("timeID");
        let params = {
          id: id,
          type: 0,
        };
        let resData = await this.$Api.Home.putClass(params);
      },
      //统计视频播放次数
      async statistics(id) {
        // console.log('视频id', id);
        clearInterval(this.startTimer);
        const info = JSON.parse(window.sessionStorage.getItem('data'))
        let data = {
          courseId: info.textId,
          schoolId: info.schoolId,
          classId: info.classId,
          unitId: id,
          type: 1,
        }
        let resData = await this.$Api.Home.startClass(data);

        if (resData.data != '') {
          this.startTimer = window.setInterval(() => {
            setTimeout(() => {
              this.statisticsTime(resData.data)
            }, 0)
          }, 60000)

        }
      },
      async statisticsTime(startId) {
        let params = {
          id: startId,
          type: 1,
        };
        let resData = await this.$Api.Home.putClass(params);
        // console.log('startId', startId)
      },
      //返回上一页
      goback() {
        this.putdate();
        this.$router.go(-1);
        clearInterval(this.startTimer);
        clearInterval(this.timer);
      },
      //返回上一页
      //切换模块（播放/PPT/写字面板）
      toggleType(type) {
        this.contentType = type;
        // this.playerdom.on('s2j_onVideoPause', this.attpause);

        if (type == "play") {} else if (type == "ppt") {
          this.playerdom.j2s_pauseVideo();
        } else {
          this.playerdom.j2s_pauseVideo();
          this.font_index = this.activeindex;
          this.gif_url = this.textlist[this.activeindex].animateUrl;
          let aurl = this.textlist[this.activeindex].animateUrl;
          this.currentImg.url = this.textlist[this.activeindex].animateUrl;
          this.canvasStore = this.textlist[this.activeindex].animateUrl;
          this.baseMap = this.textlist[this.activeindex].animateUrl;
          this.handleInitCanvas(aurl);
        }
      },
      //切换模块（播放/PPT/写字面板）

      //gif 播放暂停
      gif_show() {
        if ("getContext" in document.createElement("canvas")) {
          HTMLImageElement.prototype.play = function () {
            if (this.storeCanvas) {
              // 移除存储的canvas
              this.storeCanvas.parentElement.removeChild(this.storeCanvas);
              this.storeCanvas = null;
              // 透明度还原
              let image = document.getElementById("git");
              image.style.opacity = "";
            }
            if (this.storeUrl) {
              this.src = this.storeUrl;
            }
          };
          HTMLImageElement.prototype.stop = function () {
            var canvas = document.createElement("canvas");
            // 尺寸
            var width = this.width,
              height = this.height;
            if (width && height) {
              // 存储之前的地址
              if (!this.storeUrl) {
                this.storeUrl = this.src;
              }
              // canvas大小
              canvas.width = width;
              canvas.height = height;
              // 绘制图片帧（第一帧）
              canvas.getContext("2d").drawImage(this, 0, 0, width, height);
              // 重置当前图片
              try {
                this.src = canvas.toDataURL("image/gif");
              } catch (e) {
                // 跨域
                this.removeAttribute("src");
                // 载入canvas元素
                canvas.style.position = "absolute";
                // 前面插入图片
                this.parentElement.insertBefore(canvas, this);
                // 隐藏原图
                this.style.opacity = "0";
                // 存储canvas
                this.storeCanvas = canvas;
              }
            }
          };
        }
      },
      gif_btn(type) {
        let image = document.getElementById("git");
        if (this.gif_num == 0) {
          image.stop();
          this.gif_num++;
        } else if (this.gif_num == 1) {
          let image = document.getElementById("git");
          image.play();
          image.src = this.gif_url;
          this.gif_num = 0;
        }
      },
      //右侧文字滚动
      slidePre() {
        if (this.contentType == "play" || this.contentType == "font") {
          this.$refs.slider.$emit("slidePre");
        } else if (this.contentType == "ppt") {
          this.$refs.slider.$emit("slidePre");
          this.$refs.pptref.$emit("slideTo", this.activeindex);
        }
      },
      slideNext() {
        if (this.contentType == "play" || this.contentType == "font") {
          this.$refs.slider.$emit("slideNext");
        } else if (this.contentType == "ppt") {
          this.$refs.pptref.$emit("slideTo", this.activeindex * 2);
        }
      },
      slide(data) {
        if (data.currentPage == 0) {
          this.t_triangle_show = false;
          this.b_triangle_show = true;
        } else if (data.currentPage < this.textlist.length - 5) {
          this.t_triangle_show = true;
          this.b_triangle_show = true;
        } else {
          this.t_triangle_show = true;
          this.b_triangle_show = false;
        }
      },
      onTap(data) {},
      onInit(data) {},
      changebtn(index, id) {
        this.activeindex = index;
        if (this.contentType == "play") {
          this.attplayShow = false;
          this.getv(this.textlist[this.activeindex].hardVideo1);
        } else if (this.contentType == "ppt") {
          this.$refs.pptref.$emit("slideTo", this.activeindex * 2);
        } else {
          this.can_show1 = true;
          this.can_show2 = false;
          this.gif_url = this.textlist[this.activeindex].animateUrl;
          let aurl = this.textlist[this.activeindex].animateUrl;
          this.currentImg.url = this.textlist[this.activeindex].animateUrl;
          this.canvasStore = this.textlist[this.activeindex].animateUrl;
          this.baseMap = this.textlist[this.activeindex].animateUrl;
          this.handleInitCanvas(aurl);
        }
      },

      //画布、
      //ppt 切换
      slidePre_ppt() {
        this.$refs.pptref.$emit("slidePre");
      },
      slideNext_ppt() {
        this.$refs.pptref.$emit("slideNext");
      },

      slide_ppt(data) {
        // this.activeindex = data.currentPage;
        //切换判断
        // if (data.currentPage == 0) {
        //   this.l_triangle_show = false;
        //   this.r_triangle_show = true;
        // } else if (data.currentPage < this.pptlist.length - 1) {
        //   this.l_triangle_show = true;
        //   this.r_triangle_show = true;
        // } else {
        //   this.l_triangle_show = true;
        //   this.r_triangle_show = false;
        // }
        // if (data.currentPage % 2 == 0) {
        //   this.activeindex = data.currentPage / 2;
        //   if (this.activeindex > 4) {
        //     this.$refs.slider.$emit("slideNext");
        //     this.activeindex = data.currentPage / 2;
        //   } else if (this.activeindex < 5) {
        //     this.$refs.slider.$emit("slidePre");
        //     this.activeindex = data.currentPage / 2;
        //   }
        // }
      },
      onTap_ppt(data) {

      },
      onInit_ppt(data) {

      },
      //ppt 切换
      // 画板
      /** 工具切换*/
      handleChangeToolType(type) {
        this.can_show1 = false;
        this.can_show2 = true;
        this.activeTool = type;
        switch (type) {
          case 1:
            this.strokes = 5;
            this.cursor = `url('${cursors.pen}'),auto`;
            break;
          case 5:
            this.cursor = `url('${cursors.eraser}'),auto`;

            break;
        }
        this.handleDrawCanvas(type);
      },
      /** 初始化画布*/
      handleInitCanvas(aurl) {
        this.currentImg = {
          url: aurl,
          width: "",
          height: "",
          scale: 1,
          index: 0,
        };
        this.canvasStore = [aurl];
        this.prevDis = true;
        this.nextDis = true;
        // // 用于绘制的画板
        this.canvas_front = document.getElementById("ctx_front");
        // 用于生成绘制后图片的画板
        this.canvas_back = document.getElementById("ctx_back");
        // this.canvas_back.style.border = '1px solid red'
        // 底图画板，橡皮擦除时获取像素放到绘制画板中，达到不擦出底图的效果
        this.canvas_base = document.getElementById("ctx_base");
        //获取

        this.ctx_base = this.canvas_base.getContext("2d");
        this.ctx_front = this.canvas_front.getContext("2d");
        this.ctx_back = this.canvas_back.getContext("2d");
        this.ctx_front.strokeStyle = this.defaultColor;
        let img = new Image();
        img.crossOrigin = "";
        img.src = this.baseMap;
        let _this = this;
        img.onload = function () {
          let width = parseInt(this.width);
          let height = parseInt(this.height);
          _this.currentImg.width = width;
          _this.currentImg.height = height;
          _this.canvas_front.width = width;
          _this.canvas_front.height = height;
          _this.canvas_back.width = width;
          _this.canvas_back.height = height;
          _this.canvas_base.width = width;
          _this.canvas_base.height = height;
          _this.ctx_front.drawImage(this, 0, 0, width, height);
          _this.ctx_back.drawImage(this, 0, 0, width, height);
          _this.ctx_base.drawImage(this, 0, 0, width, height);
        };
      },
      //画笔颜色
      color_btn(item) {
        this.defaultColor = item;
        this.strokes = 10;
        this.handleChangeToolType(1);
      },
      //画笔颜色
      //橡皮擦大小
      eraser_btn(item) {
        this.strokes = item;
        this.handleChangeToolType(5);
      },
      //橡皮擦大小
      /** 清除画布*/
      handleClearCanvas() {
        this.can_show1 = false;
        this.can_show2 = true;
        let aurl = this.textlist[this.activeindex].animateUrl;
        this.handleInitCanvas(aurl);
      },
      handleFrommatCanvas() {
        this.ctx_front.clearRect(
          0,
          0,
          this.canvas_front.width,
          this.canvas_front.height
        );
      },
      handleDrawCanvas(type) {
        this.canDraw = false;
        let sx, sy, mx, my;
        let text = document.getElementById("text");
        //鼠标按下
        let mousedown = (e) => {
          this.ctx_front.strokeStyle = this.defaultColor;
          this.ctx_front.lineWidth = this.strokes;
          e = e || window.event;
          var style = window.getComputedStyle(this.canvas_front, null);
          var cssWidth = parseFloat(style["width"]);
          var cssHeight = parseFloat(style["height"]);
          var scaleX = this.canvas_front.width / cssWidth; // 水平方向的缩放因子
          var scaleY = this.canvas_front.height / cssHeight; // 垂直方向的缩放因子

          var sx = e.clientX + 4;
          var sy = e.clientY + 26;
          var rect = this.canvas_front.getBoundingClientRect();
          sx -= rect.left;
          sy -= rect.top;
          sx *= scaleX; // 修正水平方向的坐标
          sy *= scaleY; // 修正垂直方向的坐标
          const cbx = this.ctx_base.getImageData(
            sx - this.strokes / 2,
            sy - 12 - this.strokes / 2,
            this.strokes * 2,
            this.strokes * 2
          );
          this.ctx_front.moveTo(sx, sy);
          this.canDraw = true;
          switch (type) {
            case 1:
              this.ctx_front.beginPath();
              break;
            case 5:
              this.ctx_front.putImageData(
                cbx,
                sx - this.strokes / 2,
                sy - 12 - this.strokes / 2,
              );
              break;
          }
        };
        let mousemove = (e) => {
          e = e || window.event;
          var style = window.getComputedStyle(this.canvas_front, null);
          var cssWidth = parseFloat(style["width"]);
          var cssHeight = parseFloat(style["height"]);
          var scaleX = this.canvas_front.width / cssWidth; // 水平方向的缩放因子
          var scaleY = this.canvas_front.height / cssHeight; // 垂直方向的缩放因子

          var mx = e.clientX + 4;
          var my = e.clientY + 26;
          var rect = this.canvas_front.getBoundingClientRect();
          mx -= rect.left;
          my -= rect.top;
          mx *= scaleX; // 修正水平方向的坐标
          my *= scaleY; // 修正垂直方向的坐标
          const cbx = this.ctx_base.getImageData(
            mx - this.strokes / 2,
            my - 12 - this.strokes / 2,
            this.strokes * 4,
            this.strokes * 4
          );
          if (this.canDraw) {
            switch (type) {
              case 1:
                this.ctx_front.lineTo(mx, my);
                this.ctx_front.stroke();
                break;
              case 5:
                this.ctx_front.putImageData(
                  cbx,
                  mx - this.strokes / 2,
                  my - 12 - this.strokes / 2,
                );
                break;
            }
          }
        };
        let mouseup = () => {
          if (this.canDraw) {
            this.canDraw = false;
            this.ctx_front.closePath();
          }
        };
        this.canvas_front.onmousedown = (e) => mousedown(e);
        this.canvas_front.onmousemove = (e) => mousemove(e);
        this.canvas_front.onmouseup = (e) => mouseup(e);
        this.canvas_front.onmouseout = (e) => mouseup(e);
        this.canvas_front.onmouseleave = (e) => mouseup(e);
      },
      // 画板
    },
    destroyed() {
      window.clearInterval(this.timer);
      window.clearInterval(this.startTimer);
    }
  };
</script>
<style lang="less">
  @import "less/attend.less";
</style>